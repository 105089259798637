.malvinas-overlay {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    user-select: none;
    pointer-events: none;
    position: absolute;
    width: max-content;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    padding: 3px;
    border-radius: 10px;
    border: 1px solid #cccccc;
}
